<template>
    <div>
        <ListTitleBar v-on:add="add" />
        <div class="main-container">
            <div class="header">
                <Input type="text" v-model="query.name" clearable placeholder="租户名称" style="width: 300px" />
                <Button type="primary" @click="search" style="margin-left: 10px">查询</Button>
            </div>
            <div class="list-area">
                <Table stripe border :columns="columns" :data="rows"></Table>
            </div>  
            <div class="pagination" v-if="totalSize / query.pageSize > 1">
                <Page @on-change="pageChange" :total="totalSize" :pageSize="query.pageSize" show-elevator show-total />
            </div>              
        </div>
    </div>
</template>
<script>
import ListTitleBar from "@/components/client-info/ListTitleBar";
import { getList, disable, enable, del } from "@/api/client-info";

export default {
    name: "ClientInfoList",
    components: {
        ListTitleBar
    },
    created() {
        this.getClientInfoList();
    },
    data() {
        return {
            query: {
                name: "",
                pageNum: 1,
                pageSize: 10
            },
            columns: [
                { title: "客户名称", key: "name"},
                { title: "Client ID", key: "clientId", width: 250},
                { title: "Client Secret", key: "clientSecret"},
                { 
                    title: "状态", 
                    width: 100, 
                    key: "disabled",
                    align: "center",
                    render: (h, params) => {
                        if (params.row.disabled) {
                            return h("span", "禁用");
                        } else {
                            return h("span", "启用");
                        }
                    }
                },
                { title: "添加时间", key: "createTime", width: 250},
                {
                    title: "操作",
                    key: "action",
                    width: 300,
                    align: "center",
                    render: (h, params) => {
                        let buttons = [];
                        if (this.rows[params.index].disabled) {
                            buttons.push(h('Button', {
                                    props: {
                                        type: 'success',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.enable(params.index)
                                        }
                                    }
                                }, '启用'));
                        } else {
                            buttons.push(h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.disable(params.index)
                                        }
                                    }
                                }, '禁用'));
                        }
                        buttons.push(h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.remove(params.index)
                                        }
                                    }
                                }, '删除'));
                                buttons.push(h('Button', {
                                    props: {
                                        size: 'small'
                                    },
                                    on: {
                                        click: () => {
                                            this.gotoEdit(params.index)
                                        }
                                    }
                                }, '编辑'));
                        // buttons.push(h('Button', {
                        //             props: {
                        //                 size: 'small'
                        //             },
                        //             on: {
                        //                 click: () => {
                        //                     this.gotoUser(params.index)
                        //                 }
                        //             }
                        //         }, '账号管理'));
                        return buttons;
                    }
                }
            ],
            rows: [],
            totalSize: 0
        }
    },
    methods: {
        getClientInfoList() {
            getList(this.query).then(response => {
                this.rows = response.data;
                this.totalSize = response.totalSize;
            });
        },
        pageChange(pageNum) {
            this.query.pageNum = pageNum;
            this.getClientInfoList();
        },
        search() {
            this.query.pageNum = 1;
            this.getClientInfoList();
        },
        add() {
            this.$router.push({name: "client-info-add"});
        },
        enable(index) {
            enable(this.rows[index].id).then(() => {
                this.$Message.success("启用成功！");
                this.getClientInfoList();
            });
        },
        disable(index) {
            disable(this.rows[index].id).then(() => {
                this.$Message.success("禁用成功！");
                this.getClientInfoList();
            });
        },
        remove(index) {
            this.$Modal.confirm({
                title: '确定要删除该租户吗？',
                onOk: () => {
                    del(this.rows[index].id).then(() => {
                        this.$Message.success("删除成功！");
                        this.getClientInfoList();
                    });
                }
            });
        },
        gotoUser(index) {
            this.$router.push({name: "client-info-admin-user-list", params: {id: this.rows[index].id}});
        },
        gotoEdit(index) {
            this.$router.push({name: "client-info-edit", params: {id: this.rows[index].id}});
        }
    }
}
</script>
<style lang="less" scoped>
    
    .list-area {
        margin-top: 20px;
    }
    
</style>