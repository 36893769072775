<template>
    <div class="title-bar">
        <div class="left">
            <h2>租户列表</h2>
        </div>
        <div class="right">
            <Button type="primary" class="save-send" @click="$emit('add')">
                <i class="nazaio-iconfont nz-added-to-group" />
                新建租户
            </Button>
        </div>
    </div>
</template>
<script>
export default {
    name: "LightTitleBar"
}
</script>
<style lang="less" scoped>
.title-bar {
    position: absolute;
    height: 48px;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 2;

    .left {
        float: left;
    }

    h2 {
        float: left;
        font-size: 16px;
        color: #0A2A4C;
        font-weight: 400;
        margin-left: 10px;
        height: 48px;
        line-height: 48px;
    }

    .right {
        float: right;
        margin-top: 8px;
    }

    .save-send {
        margin-right: 16px;
    }
}
</style>